import React, { useContext, useEffect, useState } from "react";
import SVGComponent from "@src/components/partials/get-your-certificate/SVGComponent";
import { useStaticQuery, graphql } from "gatsby";
import { AppDataContext } from "@src/components/AppDataContext";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import { useLocation } from "@reach/router";
import Container from "@src/components/Container";
import { BodyText, DetailHeading, Button } from "@src/components/nessie-web";
import { Display3 } from "@src/components/partials/districtleader/styles";
import { MailIcon, LoginIcon } from "@src/components/nessie-web";
import Translate from "@src/components/translation/Translate";
import SEO from "@src/components/SEO";

const GetYourCertificate = () => {
  const location = useLocation();
  const { data: appData } = useContext(AppDataContext);
  const modalContext = useContext(ModalContext);
  const [svgUrl, setSvgUrl] = useState("");
  const [replacementText, setReplacementText] = useState("");
  const [campaignName, setCampaignName] = useState("");

  const data = useStaticQuery(graphql`
    query {
      directus {
        self_generated_certificates {
          id
          campaign_name
          SVG_template
          Add_possessive
          Use_first_name
          Use_last_name
          Use_title
        }
      }
    }
  `);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("userId");
    const certificateId = queryParams.get("certificateId");

    if (appData.userData && userId === appData.userData._id) {
      const matchingCertificate = data.directus.self_generated_certificates.find(
        (certificate) => certificate.id === certificateId,
      );
      if (matchingCertificate) {
        setSvgUrl(matchingCertificate.SVG_template);
        setCampaignName(matchingCertificate.campaign_name);

        const textParts = [];

        if (matchingCertificate.Use_title && appData.userData.title) {
          textParts.push(appData.userData.title);
        }
        if (matchingCertificate.Use_first_name && appData.userData.firstName) {
          textParts.push(appData.userData.firstName);
        }
        if (matchingCertificate.Use_last_name && appData.userData.lastName) {
          textParts.push(appData.userData.lastName);
        }

        let newText = textParts.join(" ");
        if (matchingCertificate.Add_possessive) {
          const lastChar = newText.slice(-1).toLowerCase();
          if (["s", "x", "z"].includes(lastChar)) {
            newText += "'";
          } else {
            newText += "'s";
          }
        }

        setReplacementText(newText);
      }
    }
  }, [location, appData.userData, data.directus.self_generated_certificates]);

  const handleLoginClick = () => {
    modalContext.showModal(ModalType.TeacherLogin, { noRedirect: true });
  };

  // Render content based on conditions
  const renderContent = () => {
    if (!appData.userData) {
      return (
        <div css={{ textAlign: "center", display: "flex", flexDirection: "column", gap: 36 }}>
          <Display3>
            <Translate path="pdf_generator.log_in" />
          </Display3>
          <Button icon={<LoginIcon />} onClick={handleLoginClick}>
            <Translate path="pdf_generator.log_in_teacher" />
          </Button>
        </div>
      );
    } else if (!svgUrl) {
      return (
        <div css={{ maxWidth: 700, textAlign: "center", display: "flex", flexDirection: "column", gap: 36 }}>
          <Display3>
            <Translate path="pdf_generator.oops" />
          </Display3>
          <BodyText>
            <Translate path="pdf_generator.error_message" />
          </BodyText>
          <Button icon={<MailIcon />} target="_blank" href="mailto:internationalcommunity@classdojo.com">
            <Translate path="pdf_generator.write_us" />
          </Button>
        </div>
      );
    } else {
      return <SVGComponent svgUrl={svgUrl} replacementText={replacementText} campaignName={campaignName} />;
    }
  };

  return (
    <>
      <SEO />
      <div css={{ minHeight: "50vh", display: "flex", alignItems: "center" }}>
        <Container css={{ justifyContent: "center", display: "flex", paddingBlock: 50 }}>{renderContent()}</Container>
      </div>
    </>
  );
};

export default GetYourCertificate;
